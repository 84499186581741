import React from "react";

import pastEventsStyle from "./pastEvents.module.css";
import Head from "../components/head";
import { graphql } from "gatsby";

export const query = graphql`
  query {
    allContentfulEvent(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          date(formatString: "MMMM DD, YYYY")
          time
          locationAddress
        }
      }
    }
  }
`;

class PastEvents extends React.Component {
  state = {
    eventDates: [],
  };
  componentDidMount() {
    let tempArr = [];

    for (let i = 0; i < this.props.data.allContentfulEvent.edges.length; i++) {
      let todaysDate = new Date();
      let dateString = todaysDate.getTime();
      let eventDate = new Date(
        this.props.data.allContentfulEvent.edges[i].node.date +
          " " +
          this.props.data.allContentfulEvent.edges[i].node.time
      );

      let eventDateString = eventDate.getTime();

      if (
        eventDateString < dateString &&
        this.props.data.allContentfulEvent.edges[i].node.title !== "null"
      ) {
        tempArr.push(this.props.data.allContentfulEvent.edges[i]);
      }
    }

    //add to state.
    this.setState({ eventDates: tempArr });
  }
  render() {
    return (
      <>
      <Head title="TSS past events"></Head>
      <div className={pastEventsStyle.pastEventsContainer}>
        <h1>Past Events</h1>

        {this.state.eventDates.map(edge => {
          return (
            <div>
              <p>
                <strong>{edge.node.date}</strong> - {edge.node.time} -{" "}
                {edge.node.title}
              </p>
              <h3>{edge.node.locationAddress}</h3>
            </div>
          );
        })}
      </div>
      </>
    );
  }
}
export default PastEvents;
